import {NgForOf, NgIf} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {NbAuthService} from '@nebular/auth';
import {NbSelectModule} from '@nebular/theme';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ApiService} from '../../@core/utils/api.service';

@Component({
	selector: 'ngx-lang-picker',
	standalone: true,
	templateUrl: './lang-picker.component.html',
	imports: [
		NbSelectModule,
		TranslateModule,
		NgIf,
		NgForOf,
	],
})
export class LangPickerComponent implements OnInit {

	constructor(private authService: NbAuthService, protected translate: TranslateService, private apiService: ApiService) {
	}

	ngOnInit(): void {
		this.authService.onTokenChange().subscribe(token => {
			this.apiService.me().subscribe((response) => {
				if (response['lang']) {
					this.changeLang(response['lang'], false);
				}
			});
		});
		this.translate.addLangs(['FR','EN', 'PT', 'NL']);
		this.changeLang(localStorage.getItem('locale') ? localStorage.getItem('locale') : 'FR', false);
	}

	public changeLang($event: any, save = true): void {
		if (!['FR', 'EN','NL', 'PT'].includes($event)) {
			$event = 'FR';
			save = false;
		}
		this.translate.setDefaultLang($event);
		this.translate.use($event).subscribe(tmp => {
		});
		localStorage.setItem('locale', $event);
		if (save) {
			this.apiService.updateUserLang($event).subscribe(value => {
			});
		}
	}
}
