import {EventEmitter, Injectable, Output} from '@angular/core';
import {NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/operators';
import {ApiService} from './api.service';

export interface Session {
	fournisseur: any;
	restaurant: any;
	wantedUrl?: string;
}

export interface RuntimeSession {
	permissions: object[];
	neededPermissions: string;
}


@Injectable({
	providedIn: 'root',
})
export class SessionService {
	public static readonly SESSION_KEY = 'groin_session';
	@Output() restaurantChange: EventEmitter<number> = new EventEmitter();
	@Output() permissionChange: EventEmitter<string> = new EventEmitter();
	@Output() permissionNeededChange: EventEmitter<string> = new EventEmitter();
	runtimeSession: RuntimeSession;

	constructor(private authService: NbAuthService, private apiService: ApiService) {
		SessionService.getSession();
		this.loadPermissions();
	}

	public static setWantedUrl(url: string): void {
		const session = SessionService.getSession();
		session.wantedUrl = url;
		console.log(session.wantedUrl);
		SessionService.setSession(session);
	}

	public static getWantedUrl(): string | null {
		const session = SessionService.getSession();
		return session && session.wantedUrl ? session.wantedUrl : '/pages/dashboard';
	}

	/********** private **********/

	private static getSession(): Session {
		let session: Session = JSON.parse(localStorage.getItem(SessionService.SESSION_KEY));

		if (!session) {
			session = {
				restaurant: null,
				fournisseur: null,
				wantedUrl: null,
			};
		}
		return session;
	}

	private static setSession(session: Session) {
		if (session == null) {
			return;
		}
		if (!navigator.cookieEnabled || !localStorage) {
			return;
		}
		localStorage.setItem(SessionService.SESSION_KEY, JSON.stringify(session));
	}

	public loadPermissions(): void {
		this.apiService.getPermissions().subscribe(value => {
			this.setPermissions(value);
		});
	}

	getRestaurantChangeEmitter() {
		return this.restaurantChange;
	}

	getPermissionNeededChangeEmitter() {
		return this.permissionNeededChange;
	}

	getPermissionChangeEmitter() {
		return this.permissionChange;
	}

	getWantedUrlCheckAuth() {
		return new Promise((resolve, reject) => {
			this.authService.isAuthenticated()
				.pipe(
					tap(authenticated => {
						if (authenticated) {
							const session = SessionService.getSession();
							resolve(session && session.wantedUrl ? session.wantedUrl : '/pages/dashboard');
						} else {
							resolve(null);
						}
					}),
				);
		});
	}

	getRestaurant() {
		const session = SessionService.getSession();
		return (session && session.restaurant) ? session.restaurant : null;
	}

	getRestaurantId() {
		return (this.getRestaurant() ? this.getRestaurant()['id'] : null);
	}

	setRestaurant(restaurant) {
		const session = SessionService.getSession();
		session.restaurant = restaurant;
		SessionService.setSession(session);
		this.restaurantChange.emit(restaurant ? restaurant.id : null);
	}

	getPermissions(): object[] {
		const session = this.getRuntimeSession();
		return (session && session.permissions) ? session.permissions : [];
	}

	getPermissionsRestaurant(restaurantId) {
		return this.getPermissions().find(value => {
			return value['restaurant_id'] === restaurantId;
		});
	}

	getPermissionRestaurant(restaurantId, permName) {
		const perm = this.getPermissionsRestaurant(restaurantId);
		if (!perm) {
			return false;
		}
		return (perm[permName] === 1);
	}

	setPermissions(permissions: object[]) {
		const session = this.getRuntimeSession();
		session.permissions = permissions;
		this.setRuntimeSession(session);
		this.permissionChange.emit();
	}

	getNeededPermissions() {
		const session = this.getRuntimeSession();
		return (session && session.neededPermissions) ? session.neededPermissions : null;
	}

	hasPermission(permissionName, restaurantId = null) {
		let hasPerm = false;
		this.getPermissions().filter(obj => {
			if (restaurantId) {
				if (obj[permissionName] === 1 && obj['restaurant_id'] === restaurantId) {
					hasPerm = true;
					return;
				}
			} else {
				if (obj[permissionName] === 1) {
					hasPerm = true;
					return;
				}
			}
		});
		return hasPerm;
	}

	setNeededPermissions(neededPermissions) {
		const session = this.getRuntimeSession();
		if (neededPermissions !== session.neededPermissions) {
			session.neededPermissions = neededPermissions;
			this.setRuntimeSession(session);
			this.permissionNeededChange.emit(neededPermissions);
		}
	}

	emptySession() {
		localStorage.removeItem(SessionService.SESSION_KEY);
	}

	private getRuntimeSession(): RuntimeSession {
		if (!this.runtimeSession) {
			this.runtimeSession = {
				neededPermissions: null,
				permissions: [],
			};
		}
		return this.runtimeSession;
	}

	private setRuntimeSession(session: RuntimeSession) {
		if (session == null) {
			return;
		}
		this.runtimeSession = session;
	}
}
